import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import berg from '../assets/images/berg2.png';

import Header from './header';
import './layout.css';

import SimpleReactLightbox from 'simple-react-lightbox';

const Layout = ({ children, bg }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'module');
    script.src = 'https://w-cdn.rentware.io/dist/rentware-widgets.esm.js';
    script.async = false;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.innerHTML = `
    window.RENTWARE_BASE_API_URL = 'https://peakmedia.rentware.io';
    window.RENTWARE_LANGUAGE = 'de-DE';
`;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script2);
    };
  });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <SimpleReactLightbox>
        <Header siteTitle={data.site.siteMetadata.title} />
        <rentware-checkout />
        <main className={bg}>
          <div id="fade-ani">{children}</div>
        </main>
        <footer className="relative pt-16 pb-12 bg-menu sm:pt-20 md:pt-24 xl:pt-32 sm:pb-20">
          <img alt="Peak Motors" src={berg} className="absolute w-full h-[120px] bottom-0 z-10" />
          <div className="px-4 mx-auto md:max-w-screen-xl 2xl:max-w-screen-2xl sm:px-6 md:px-8">
            <ul className="grid text-sm font-medium pb-14 sm:pb-20 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10 gap-x-4">
              <li className="row-span-2 space-y-5">
                <h2 className="text-lg font-semibold tracking-wide text-gray-200 uppercase">Kontakt</h2>
                <ul className="space-y-4 text-gray-400">
                  <li>
                    PEAK MOTORS
                    <br />
                    Durchholzen 61
                    <br />
                    6344 Walchsee
                    <br />
                    Österreich
                  </li>
                  <li>
                  <a href="tel:+4353732055575">+43 5373 20 555 75</a>
                  <br />
                  <a href="tel:+4367762844347">+43 677 62 84 43 47</a>
                  <br />
                    <a href="mailto:info@peakmotors.at">info@peakmotors.at</a>
                  </li>
                  <li>
                    <a className="mr-4 transition-colors duration-200 hover:text-gray-900" href="https://www.instagram.com/peak.motors/" target="_blank" rel="noreferrer">
                      <StaticImage src="../assets/images/instagram.png" height={20} alt="" placeholder="blurred" />
                    </a>
                    <a className="transition-colors duration-200 hover:text-gray-900" href="https://api.whatsapp.com/send?phone=4367762844347" target="_blank" rel="noreferrer">
                      <StaticImage src="../assets/images/whatsapp.png" height={20} alt="" placeholder="blurred" />
                    </a>
                    {/* <a className="transition-colors duration-200 hover:text-gray-900" href="#">
                      <StaticImage src="../assets/images/youtube.png" height={20} alt="" />
                    </a> */}
                  </li>
                </ul>
              </li>
              <li className="row-span-2 space-y-5">
                <h2 className="text-lg font-semibold tracking-wide text-gray-200 uppercase">INFORMATIONEN</h2>
                <ul className="space-y-4">
                  <li>
                    <Link to="/ueber-uns">Über Uns</Link>
                  </li>
                  <li>
                    <Link to="/agb">Mietbedingungen</Link>
                  </li>
                  <li>
                    <Link to="/impressum">Impressum</Link>
                  </li>
                  <li>
                    <Link to="/datenschutz">Datenschutz</Link>
                  </li>
                  <li>
                    <Link to="/kontakt">Kontakt</Link>
                  </li>
                </ul>
              </li>
              <li className="row-span-2 space-y-5">
                <h2 className="text-lg font-semibold tracking-wide text-gray-200 uppercase">Shop</h2>
                <ul className="space-y-4">
                  <li>
                    <a href="https://peakmedia.shop/" target="blank">
                      Merchandise
                    </a>
                  </li>
                  <li>
                    <Link to="/gutscheine">Gutscheine</Link>
                  </li>
                </ul>
              </li>
              <li className="row-span-2 space-y-5">
                <h2 className="text-lg font-semibold tracking-wide text-gray-200 uppercase">Fahrzeuge</h2>
                <ul className="space-y-4">
                  {/* <li>
                    <Link to="/bmw-m3">BMW M3 Competition</Link>
                  </li>
                  <li>
                    <Link to="/bmw-m4">BMW M4 Competition</Link>
                  </li> */}
                   <li>
                    <Link to="/porsche-gts">Porsche Taycan GTS</Link>
                  </li>                 
                  <li>
                    <Link to="/porsche-gt4">Porsche Cayman GT4</Link>
                  </li>
                  <li>
                    <Link to="/porsche-gt3">Porsche 911 GT3</Link>
                  </li>
                  <li>
                    <Link to="/gmc-event-hummer">GMC Event Hummer H1</Link>
                  </li>
                </ul>
              </li>
              <li className="space-y-5">
                <h2 className="text-xs font-semibold tracking-wide text-gray-900 uppercase">Partner</h2>
                <ul className="space-y-4">
                  <li>
                    <h3 className="mb-2 text-sm tracking-wider text-gray-200">Part of the </h3>
                    <a className="transition-colors duration-200 hover:text-gray-900" href="https://www.peak-world.com/" target="_blank" rel="noreferrer">
                      <StaticImage src="../assets/images/peakworld.png" placeholder="blurred" width={320} alt="" />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </footer>
        <div className="w-full py-6 text-sm font-light leading-relaxed text-center text-gray-400 uppercase bg-black md:py-12">
          © {new Date().getFullYear()} Peakmedia Vertriebs GmbH - Alle Rechte vorbehalten.
          <br />
          Website und Vermietungs&shy;software von
          {` `}
          <a href="https://rentware.io" target="_blank" rel="noreferrer" className="text-white ">
            Rentware
          </a>
        </div>
        {/* <Covid /> */}
      </SimpleReactLightbox>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
